import { useEffect, useState } from "react";
import styled from "styled-components";
import { API_BASE, TOASTPROPS } from "../shared/Constants";
import { useAuth0 } from "@auth0/auth0-react";
import { parseDate } from "../shared/Utils";
import { toast, ToastContainer } from "react-toastify";
import { Skeleton } from "@mui/material";

interface Model {
  max_tokens: number;
  token_rate_limit_input: number;
  token_rate_limit_output: number;
  user_tier: string;
  requests_per_minute: number;
  input_price: number;
  output_price: number;
  update_date: string;
  knowledge_cutoff?: string; 
  alias?: string;
  [key: string]: any;
}

interface DeprecatedModel {
  name: string;
  deprecation_date: string;
  replacement_model: string;
}

interface ModelList {
  [modelName: string]: Model;
}

interface DeprecatedModelList {
  [modelName: string]: DeprecatedModel;
}

interface Feature {
  key: keyof Model;
  label: string;
  format: (value: any) => string | JSX.Element | JSX.Element[] | null;
}

function Models() {
  const [loading, setLoading] = useState<boolean>(true);
  const [models, setModels] = useState<ModelList>({});
  const [deprecatedModelList, setDeprecatedModelList] = useState<DeprecatedModelList>({});

  const { getAccessTokenSilently } = useAuth0();

  const parsePrice = (price: number): string => {
    let parsedPrice = (price * 1e6).toFixed(2);
    parsedPrice = parsedPrice.replace(".", ",");
    return parsedPrice;
  };

  const parseTokenRateLimit = (rate: number): string => {
    if (rate >= 1_000_000) {
      const millionRate = rate / 1_000_000;
      const formattedRate =
        millionRate % 1 === 0
          ? millionRate.toFixed(0)
          : millionRate.toFixed(1).replace(".", ",");
      return `${formattedRate} ${millionRate > 1 ? "milhões" : "milhão"}`;
    } else if (rate >= 1000) {
      return `${(rate / 1000).toFixed(0)} mil`;
    } else {
      return rate.toString();
    }
  };

  useEffect(() => {
    const getModels = async () => {
      try {
        setLoading(true);
        const header = new Headers();
        const token = await getAccessTokenSilently();
        header.append("Authorization", `Auth ${token}`);
        const response = await fetch(`${API_BASE}/api/chat/models`, {
          method: "GET",
          headers: header,
        });

        if (!response.ok) {
          throw new Error("Failed to fetch models");
        }

        const data = await response.json();
        setModels(data["models"] || {});
        setDeprecatedModelList(data["deprecated_models"] || {});
        setLoading(false);
      } catch (error) {
        toast.error("Erro ao recuperar modelos disponíveis.", TOASTPROPS);
        setLoading(false);
      }
    };

    getModels();
  }, [getAccessTokenSilently]);

  const features: Feature[] = [
    {
      key: "max_tokens",
      label: "Contexto Máximo",
      format: (value: number) => `${value} tokens`,
    },
    {
      key: "user_tier",
      label: "Tier de Rate Limit",
      format: (value: any) => (
        <>
          <a
            href="https://docs.maritaca.ai/pt/rate-limits#n%C3%ADveis-de-uso"
            target="_blank"
            rel="noopener noreferrer"
          >
            {value}
          </a>
        </>
      ),
    },
    {
      key: "token_rate_limit_input",
      label: "Rate limit - Entrada [TPM]",
      format: parseTokenRateLimit,
    },
    {
      key: "token_rate_limit_output",
      label: "Rate limit - Saída [TPM]",
      format: parseTokenRateLimit,
    },
    {
      key: "requests_per_minute",
      label: "Requisições [RPM]",
      format: (value: number) => value.toString(),
    },
    {
      key: "input_price",
      label: "Preço por 1 milhão de tokens de Entrada",
      format: (value: number) => `R$ ${parsePrice(value)}`,
    },
    {
      key: "output_price",
      label: "Preço por 1 milhão de tokens de Saída",
      format: (value: number) => `R$ ${parsePrice(value)}`,
    },
    {
      key: "update_date",
      label: "Data de Lançamento",
      format: parseDate,
    },
    {
      key: "knowledge_cutoff",
      label: "Conhecimento",
      format: (value: string) => value,
    },
    {
      key: "alias",
      label: "Outros Nomes",
      format: (value: string) =>
        value
          ? value.split(",").map((alias, index) => (
              <div key={index}>{alias.trim()}</div>
            ))
          : null,
    },
  ];

  return (
    <Container>
      <TableContainer>
        <Table>
          <thead>
            <tr>
              <th></th>
              {loading &&
                Array.from({ length: 4 }).map((_, index) => (
                  <th key={index}>
                    <Skeleton variant="text" width={100} />
                  </th>
                ))}
              {!loading &&
                Object.keys(models).map((modelName) => (
                  <th key={modelName}>{modelName}</th>
                ))}
            </tr>
          </thead>
          <tbody>
            {features.map((feature) => (
              <tr key={feature.key as string}>
                <td>
                  <strong>{feature.label}</strong>
                </td>
                {loading &&
                  Array.from({ length: 4 }).map((_, index) => (
                    <td key={index}>
                      <Skeleton variant="text" width={100} />
                    </td>
                  ))}
                {!loading &&
                  Object.keys(models).map((modelName) => {
                    const model = models[modelName];
                    const value = model[feature.key];
                    return <td key={modelName}>{feature.format(value)}</td>;
                  })}
              </tr>
            ))}
          </tbody>
        </Table>
      </TableContainer>

      <Text>
        TPM = Tokens por minuto. <br /> RPM = Requisições por minuto.
      </Text>

      {deprecatedModelList && Object.keys(deprecatedModelList).length > 0 && (
        <>
          <SubTitle>Modelos Descontinuados</SubTitle>
          <TableContainer>
            <Table>
              <thead>
                <tr>
                  <th>Nome do Modelo</th>
                  <th>Data da Aposentadoria</th>
                  <th>Recomendação</th>
                </tr>
              </thead>
              <tbody>
                {loading
                  ? Array.from({ length: 3 }).map((_, index) => (
                      <tr key={index}>
                        <td>
                          <Skeleton variant="text" width={100} />
                        </td>
                        <td>
                          <Skeleton variant="text" width={100} />
                        </td>
                        <td>
                          <Skeleton variant="text" width={100} />
                        </td>
                      </tr>
                    ))
                  : Object.keys(deprecatedModelList).map((modelKey) => {
                      const depModel = deprecatedModelList[modelKey];
                      return (
                        <tr key={modelKey}>
                          <td>{depModel.name}</td>
                          <td>{parseDate(depModel.deprecation_date)}</td>
                          <td>{depModel.replacement_model}</td>
                        </tr>
                      );
                    })}
              </tbody>
            </Table>
          </TableContainer>
        </>
      )}

      <ToastContainer />
    </Container>
  );
}

export default Models;


const Warning = styled.p`
  font-size: 1.1rem;
  color: ${(props) => props.theme.red};
  font-family: Plus Jakarta Sans, sans-serif;
  line-height: 1.7rem;
  margin-top: 0px;
  margin-bottom: 0px;
`;

const TableContainer = styled.div`
  overflow-x: auto;
  min-height: 150px;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.white_background};
  margin-bottom: 40px;
`;

const Text = styled.p`
  font-size: 1.1rem;
  font-weight: 350;
  color: ${(props) => props.theme.dark_blue};
  font-family: Plus Jakarta Sans, sans-serif;
  line-height: 1.7rem;

  @media (max-width: 768px) {
    padding-right: 15px;
  }
`;

const Table = styled.table`
  max-width: 100%;

  th,
  td {
    text-align: left;
    padding-top: 15px;
  }

  th {
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  }

  th:nth-child(1),
  td:nth-child(1) {
    min-width: 250px;
    padding-right: 20px;
  }

  th:not(:first-child),
  td:not(:first-child) {
    min-width: 130px;
    padding-right: 20px;
  }

  th:last-child,
  td:last-child {
    padding-right: 5px;
  }

  @media (max-width: 768px) {
    th:nth-child(1),
    td:nth-child(1) {
      min-width: 0px;
      padding-right: 20px;
    }

    th:not(:first-child),
    td:not(:first-child) {
      min-width: 0px;
    }

    th:last-child,
    td:last-child {
      min-width: 0px;
      padding-right: 20px;
    }
  }

  font-size: 1.1rem;
  font-weight: 400;
  color: ${(props) => props.theme.dark_blue};
  font-family: Plus Jakarta Sans, sans-serif;
`;

const SubTitle = styled.h2`
  font-size: 1.3rem;
  color: ${(props) => props.theme.dark_blue};
  font-family: Plus Jakarta Sans, sans-serif;
  margin-top: 30px;
`;
